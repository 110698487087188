
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
import { ReguaCobrancaService } from '@/core/services/regua';
import { Contrato } from '@/core/models/cadastros';
import { ContratoService } from '@/core/services/cadastros';
import { ReguaCobranca } from '@/core/models/regua';

import { TratarErroApi } from '@/assets/scripts/helper';

@Component
export default class PainelCobranca extends mixins(Vue, ListPage) {
    item = new ReguaCobranca();
    dialogCadastro: boolean = false;

  filter: any = new Model();
   tab = null;

  service = new RelatorioService()
  serviceRegua = new ReguaCobrancaService();
  etapaModel = new Etapas()
  

  lista: any = [];

  paginations = []
  currentPageCobrar = 1;
  perPage = 3;
    isLoading: boolean = false;

  DialogGerenciadorContrato: boolean = false;
  contrato = new Contrato();
  contratoService = new ContratoService();
 
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi' },
    { text: 'Painel de Inadimplência', disabled: true, href: '/financeiro/painel/cobranca' }
  ];

  titulo: string = 'Painel de cobrança';
  subTitulo: string = 'tipos de bancos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

  contratosCanceladosGraphs: any[] = [
    {
      1: { text: 'JAN', value: 12 },
      2: { text: 'FEV', value: 12 },
      3: { text: 'MAR', value: 13 },
    }
  ]

  Novo(){
    this.item = new ReguaCobranca();
    this.dialogCadastro = true;
  }


  Editar(item){
    this.serviceRegua.ObterPorId(item.id, "Etapas").then(res => {
        this.item = res.data;
        this.dialogCadastro = true;
    })
  }

   Excluir(item) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.serviceRegua.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          }, 
        (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        }); 
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  }

  @Watch("options", { deep: true })
  AtualizarLista() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

    this.serviceRegua.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning");
        } else {
          this.$swal("Aviso", err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }).finally(() => (this.loading = false));
  }

  

  clienteNome:string = "";

  GetContrato(item) {
    this.contratoService.ObterPorId(item.id, "Receitas.Parcelas.Transacoes.UsuarioBoletoGerado, UnidadesAdicionais.Unidade, ContratosDigitais.Tipo, ContratosDigitais.Situacao, ContratosDigitais.Signatarios.Cliente, ContratosDigitais.Signatarios.Situacao, Receitas.Parcelas.AntecipacaoAmortizacao.Itens,TipoAmortizacao, Receitas.Parcelas.AntecipacaoComprovante, Observacoes, Receitas.Parcelas.Situacao, Receitas.Parcelas.Baixas.FormaPagamento,TipoIndice").then(
      res => {
        this.clienteNome = item.clienteNome
        this.contrato = res.data;
        this.DialogGerenciadorContrato = true;
      },
    )
  }

  ObterRegua() {
    if (this.filter.id > 0) {
        this.isLoading = true;
        this.service.ObterRelatorioPainelInadimplencia(this.filter.id).then(
          (res) => {

        if (!res || !res.data) {
        this.isLoading = false;
      }
          this.etapaModel = res.data;
          this.etapaModel.etapas = res.data.etapas
            this.isLoading = false;

        },
      )
    }
  }

  etapasContratos = [];

  GetReguaCobracaContrato() { 
    this.loading = true;
    if (this.filter.id > 0) {
      this.serviceRegua.GetContratos(this.filter.id).then(
        res => {
          this.etapasContratos = res.data.etapas;
        },
      ).finally(() => this.loading = false )
    }
  }

  @Watch("options", { deep: true })
  Atualizar() {
    this.serviceRegua.Listar(-1, -1, [], [], this.search, [], undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.filter.id = this.lista.id
        this.ObterRegua();
      },
    )
  }

  mounted() {
    this.AtualizarLista()
  }
}

class Model {
  id = null;
}

class Etapas {
  etapas: any = [];
  totalParcelas: number = 0;
  valorTotal: number = 0;
}
